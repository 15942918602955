import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Mic, Square } from 'lucide-react';
import './MessageInput.css';
import { processAudio } from '../services/audioService';

export default function MessageInput({ onSendMessage, isDisabled }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [hasPermission, setHasPermission] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  const checkPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      setHasPermission(true);
    } catch (err) {
      console.error('Error checking microphone permission:', err);
      setHasPermission(false);
    }
  }, []);

  useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const startRecording = useCallback(async () => {
    if (isRecording || isProcessing || !hasPermission) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        handleAudioProcessing(audioBlob);
      };

      if (isSafari) {
        mediaRecorderRef.current.start(500);
      } else {
        mediaRecorderRef.current.start();
      }

      setIsRecording(true);
      setRecordingTime(0);
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (err) {
      console.error('Error starting recording:', err);
      alert('Unable to start recording. Please check your microphone permissions.');
    }
  }, [isRecording, isProcessing, hasPermission, isSafari]);

  const stopRecording = useCallback(() => {
    if (!isRecording || !mediaRecorderRef.current) return;

    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    clearInterval(timerRef.current);
    setIsRecording(false);
    setRecordingTime(0);
  }, [isRecording]);

  const handleAudioProcessing = async (audioBlob) => {
    setIsProcessing(true);
    try {
      await processAudio(audioBlob);
    } catch (err) {
      console.error('Error processing audio:', err);
      alert('An error occurred while processing the audio. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === 'Space' && document.activeElement.tagName !== 'INPUT') {
        e.preventDefault();
        toggleRecording();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleRecording]);

  return (
    <div className="message-input">
      <button
        type="button"
        className={`record-button ${isRecording ? 'recording' : ''}`}
        onClick={toggleRecording}
        disabled={isDisabled || isProcessing || !hasPermission}
        aria-label={isRecording ? "Stop recording" : "Start recording"}
      >
        {isRecording ? <Square /> : <Mic />}
        <span className="button-text">
          {isRecording ? `Recording (${recordingTime}s)` : 'Tap to Record'}
        </span>
      </button>
      {isProcessing && <div className="audio-loading">Processing...</div>}
      {!hasPermission && <div className="permission-warning">Microphone access is required for recording.</div>}
    </div>
  );
}